export const fadeIn = (el, duration = 1000) => {
    el.style.opacity = 0;
    el.style.display = 'block';

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = elapsed / duration;

        el.style.opacity = Math.min(progress, 1);

        if (progress < 1) {
            window.requestAnimationFrame(animate);
        }
    }

    window.requestAnimationFrame(animate);
};

export const fadeOut = (el, duration = 1000) => {
    el.style.opacity = 1;

    let startTime;

    function animate(time) {
        if (!startTime) {
            startTime = time;
        }

        const elapsed = time - startTime;
        const progress = elapsed / duration;

        el.style.opacity = 1 - Math.min(progress, 1);

        if (progress < 1) {
            window?.requestAnimationFrame(animate);
        } else {
            el.style.display = 'none';
        }
    }

    window?.requestAnimationFrame(animate);
};

export default {
    fadeIn,
    fadeOut,
};
